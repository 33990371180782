import { defineStore } from 'pinia';
import { isLoggedIn } from '../../assets/js/utils';

export const useSettingsStore = defineStore('Settings', {
  state: () => ({
    textNotifications: true,
    pushNotifications: false,
    soundNotifications: true,
    soundNotificationsType: 'all', // all | follow
    notificationSound: '1.mp3',
    notificationVolume: 100,
    typersFormType: '30days', // 30days | month
    settlement: false,
    adsFilter: false,
    height: 0,
	  userNotifications: {
			addedNewBet: true,
		  likedBetComm: true,
			commentBetComm: true,
		  followed: true,
		  addedCoupon: true
	  }
  }),
  getters: {

  },
  actions: {
    getUserSettings() {
      if (!isLoggedIn()) {
        return;
      }
      axios
        .post(window.WP.ajaxUrl, {
          endpoint: 'api_get_user_settings',
          user_id: window.WP.user.id
        })
        .then(response => {
          if (response.data.success) {
            let res = response.data.data.settings;
						let uNotiSett = response.data.data.user_notifications_settings;
            this.textNotifications = !!Number(res.text_notifications);
            this.pushNotifications = !!Number(res.push_notifications);
            this.soundNotifications = !!Number(res.sound_notifications);
            this.soundNotificationsType = res.sound_notifications_type;
            this.notificationSound = res.sound_name;
            this.notificationVolume = Number(res.volume);
            this.typersFormType = res.form_type;
						this.userNotifications = {
							addedNewBet: !!Number(uNotiSett.added_new_bet),
							likedBetComm: !!Number(uNotiSett.liked_bet_comm),
							commentBetComm: !!Number(uNotiSett.comment_bet_comm),
							followed: !!Number(uNotiSett.followed),
							addedCoupon: !!Number(uNotiSett.added_coupon)
						}
          }
        });
    },
    async saveUserSettings() {
      return axios
        .post(window.WP.ajaxUrl, {
          endpoint: 'api_save_user_settings',
          user_id: window.WP.user.id,
          text_notifications: this.textNotifications,
          push_notifications: this.pushNotifications,
          sound_notifications: this.soundNotifications,
          sound_notifications_type: this.soundNotificationsType,
          sound_name: this.notificationSound,
          volume: this.notificationVolume,
          form_type: this.typersFormType,
        });
    }
  },
});