import { defineStore } from 'pinia';

const COUPON_LOCAL_STORAGE_KEY = 'coupon';

const getFromLocalStorage = () => {
  const coupon = localStorage.getItem(COUPON_LOCAL_STORAGE_KEY);
  return coupon ? JSON.parse(coupon) : [];
}

export const useCouponStore = defineStore('Coupon', {
  state: () => ({
    couponLikes: {
      couponID: 0,
      likes: 0
    },
    coupons: [],
    isCouponAdded: false,
    addedBets: getFromLocalStorage()
  }),
  actions: {
    setCoupons(coupons) {
      this.coupons = coupons;
    },
    setLikes(data) {
      this.couponLikes = data;
    },
    deleteCoupon(deletedCouponID) {
      this.coupons = this.coupons.filter((coupon) => parseInt(coupon.coupon_id) !== deletedCouponID)
    },
    getLikesByCouponID(couponID) {
	    return this.couponLikes.filter((coupon) => coupon.couponID === couponID)[0].likes;
    },
    addLikeByCouponID(couponID) {
      this.couponLikes.filter((coupon) => coupon.couponID === couponID)[0].likes++;
    },
    removeLikeByCouponID(couponID) {
      this.couponLikes.filter((coupon) => coupon.couponID === couponID)[0].likes--;
    },
    saveToLocalStorage() {
      localStorage.setItem(COUPON_LOCAL_STORAGE_KEY, JSON.stringify(this.addedBets))
    },
    removeCouponFromLocalStorage() {
      localStorage.removeItem(COUPON_LOCAL_STORAGE_KEY);
    }
  },
});