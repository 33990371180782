export class Notice {
  static counter = 0;
  id = 0;
  source = 'internal';
  content = '';
  icon = '';
  bgColor = '';
  dismissTime = { mobile: 2000, desktop: 3500 };
  bet = null;
  user = null;
  onClick = null;
  cancelAction = null;
  timeoutId = -1;
  closeFunction = null;
  refreshFunction = null;
  bookmaker = '';
  title = '';
  bottomLink = '';

  constructor() {
    Notice.counter++;
    this.id = Notice.counter;
  }

  setSource(source) {
    this.source = source;
    return this;
  }

  setTitle(title) {
    this.title = title;
    return this;
  }

  setContent(content) {
    this.content = content;
    return this;
  }

  setIcon(icon) {
    this.icon = icon;
    return this;
  }

  setBgColor(bgColor) {
    this.bgColor = bgColor;
    return this;
  }

  /**
   * Sets the time after which the notification will disappear.
   * Time can be a number (milliseconds) or an object.
   * @param time {Number|Object} e.g. { mobile: 2000, desktop: 3000 }
   * @return {Notice}
   */
  setDismissTime(time) {
    this.dismissTime = time;
    return this;
  }

  setBet(bet) {
    this.bet = bet;
    return this;
  }

  setUser(user) {
    this.user = user;
    return this;
  }

  setOnClick(onClick) {
    this.onClick = onClick;
    return this;
  }

  setBottomLink(bottomLink) {
    this.bottomLink = bottomLink;
    return this;
  }

  setCancelAction(action) {
    this.cancelAction = action;
    if(typeof this.dismissTime === 'object'){
      this.dismissTime.mobile += 1000;
      this.dismissTime.desktop += 1000;
    }
    else if(typeof this.dismissTime === 'number'){
      this.dismissTime += 1000;
    }
    return this;
  }

  setBookmaker(bookmaker) {
    this.bookmaker = bookmaker;
    return this;
  }
}