import { defineAsyncComponent } from 'vue';

export default {
  BettingTips: defineAsyncComponent(() => import('./BettingTips.vue')),
  UserProfile: defineAsyncComponent(() => import('./UserProfile.vue')),
  UserList: defineAsyncComponent(() => import('./UserList.vue')),
  Settings: defineAsyncComponent(() => import('./Settings.vue')),
  TournamentStats: defineAsyncComponent(() => import('./TournamentStats.vue')),
  AdminPanel: defineAsyncComponent(() => import('./AdminPanel.vue')),
	SearchPage: defineAsyncComponent(() => import('./Search.vue')),
  NewPasswordPage: defineAsyncComponent(() => import('./NewPasswordPage.vue'))
}