export const envConfig = {
  socketAddress: 'wss://zawodtyper.com:3000',
  firebaseConfig: {
    apiKey: "AIzaSyB-NO5CbkbOBrXMg90VYN9j-pkhy_EuWHA",
    authDomain: "zawodtyperdev.firebaseapp.com",
    projectId: "zawodtyperdev",
    storageBucket: "zawodtyperdev.appspot.com",
    messagingSenderId: "166165166912",
    appId: "1:166165166912:web:05bffd59af1ed29c1d58a5",
  },
  vapidKey: 'BE1YQUVRdtyV1gJH4JT8qNC2M9lJQXdIc9vEq8ViX4H0UyBjXHSlvz-IX1EPGv1prjHbGmMnkwdGQlDJYVzsaYo',
  siteUrl: 'https://testroom.pl/',
};