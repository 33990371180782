import { defineStore } from 'pinia';

export const useBetStore = defineStore('Bet', {
  state: () => ({
    newBetsCount: 0,
    newBetDiscussionsCount: 0,
    selectedTab: 'TabBets',
    lastScrollPosition: 0,
    navItems: [
      {
        query_name: 'typy',
        tab: 'TabBets',
      },
      {
        query_name: 'ranking',
        tab: 'TabRankings',
      },
      {
        query_name: 'nagrody',
        tab: 'TabPrizes',
      },
      {
        query_name: 'zasady',
        tab: 'TabRules',
      },
      {
        query_name: 'ustawienia',
        tab: 'TabSettings',
      },
      {
        query_name: 'kupon',
        tab: 'TabCoupons',
      },
    ],
  }),
  getters: { },
  actions: {
    getNavItemTabName(tabQueryName) {
      for (let navItem of this.navItems) {
        if (navItem.query_name === tabQueryName) {
          return navItem.tab;
        }
      }
      return '';
    },
    getNavItemQueryName(tabName) {
      for (let navItem of this.navItems) {
        if (navItem.tab === tabName) {
          return navItem.query_name;
        }
      }
      return '';
    },
    changeTab(tabName) {
      if (this.selectedTab === 'TabBets' && tabName !== 'TabBets') {
        window.history.replaceState(null, '',
          `${window.location.href}#${this.getNavItemQueryName(tabName)}`);
      } else if (this.selectedTab !== 'TabBets' && tabName === 'TabBets') {
        window.history.replaceState(null, '',
          `${window.location.href.substring(0, window.location.href.indexOf('#'))}`);
      } else {
        window.history.replaceState(null, '',
          `${window.location.href.substring(0, window.location.href.indexOf('#') + 1)}${this.getNavItemQueryName(tabName)}`);
      }

      if (this.selectedTab === 'TabBets' && tabName !== 'TabBets') {
        this.lastScrollPosition = window.scrollY;
        document.getElementById('betting-container').style.height = document.querySelector('.bets-container').getBoundingClientRect().height;
      }
      this.selectedTab = tabName;
    },
  },
});