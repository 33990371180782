import { defineStore } from 'pinia';

export const useAdminStore = defineStore('Admin', {
  state: () => ({
    selectedTab: 'TabReports',
    lastScrollPosition: 0,
	  tabs: new Map([
		  ['relacje', 'TabCommentaries'],
		  ['gale', 'TabGalas'],
		  ['zawodnicy', 'TabCompetitors'],
		  ['artykuly', 'TabArticleRatings'],
		  ['analityka-klikniec', 'TabAnalytics'],
		  ['dziennikarze', 'TabJournalists'],
		  ['relacje-dodatkowe', 'TabAdditionalCommentaries'],
		  ['zgloszenia', 'TabReports'],
		  ['bukmacherzy', 'TabBookmakers'],
		  ['autorzy', 'TabAuthors'],
		  ['federacje', 'TabFederations'],
		  ['popupy', 'TabPopUps'],
		  ['kupony', 'TabCoupons'],
		  ['ligi', 'TabLeagues'],
		  ['druzyny', 'TabTeams'],
		  ['reprezentacje', 'TabRepresentations'],
		  ['porownania', 'TabTeamComparisons'],
		  ['koszulki', 'TabBookmakerShirts'],
		  ['dyscypliny', 'TabDisciplineRankings'],
		  ['analityka-odwiedzin', 'TabVisitAnalytics'],
		  ['strony-statystyk', 'TabStats']
	  ])
  }),
  getters: {

  },
  actions: {
		getKey(val) {
			return [...this.tabs].find(([key, value]) => val === value)[0];
		},
    changeTab(tabName) {
      if (this.selectedTab === 'TabReports' && tabName !== 'TabReports') {
        this.lastScrollPosition = window.scrollY;
      }
      this.selectedTab = tabName;
	    window.location.hash = this.getKey(tabName);
    },
  },
});